import React from "react"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

const UspSection = () => {
	const {t} = useTranslation("common")

	return (
		<div className="row justify-content-between">
			<div className="col-3 d-flex flex-column align-items-center text-center">
				<Image src={"/assets/icons/delivery-myhomeplan.svg"} width={48} height={48} alt="delivery"/>
				<Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
					Kostenlose Lieferung
				</Typography><
					Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
				Bequem und einfach bis an die Bordsteinkante geliefert.
				</Typography>
			</div>
			<div className="col-3 d-flex flex-column align-items-center text-center">
				<Image src={"/assets/icons/guarantee-myhomeplan.svg"} width={48} height={48} alt="guarantee"/>
				<Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
					14 Tage Stornierungsrecht
				</Typography>
				<Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
					Storniere Deine Bestellung innerhalb von 2 Wochen
				</Typography>
			</div>
			<div className="col-3 d-flex flex-column align-items-center text-center">
				<Image src={"/assets/icons/return-myhomeplan.svg"} width={48} height={48} alt="return"/>
				<Typography className="mt-2" variant="bodyLg">2 Jahre Garantie</Typography>
				<Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
					Immer auf der sicheren Seite.
				</Typography>
			</div>
			<div className="col-3 d-flex flex-column align-items-center text-center">
				<Image src={"/assets/icons/warehouse.svg"} width={48} height={48} alt="return"/>
				<Typography className="mt-2" variant="bodyLg">Später zahlen, sofort besitzen</Typography>
				<Typography className="mt-2" variant={"bodyLg"} semanticTag={"span"}>
					Besitze das Möbelstück von Tag 1, zahle es mit 0 % Zins ab.
				</Typography>
			</div>
		</div>
	)
}

export default UspSection
