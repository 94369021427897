import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import Button from "../reusable/components/Button"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import {Slides} from "../reusable/components/Slides"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"

type Props = {
	reviews: any[],
	blackFriday?: boolean,
	cover: any,
	coverUpperParagraph: any,
	coverBottomParagraph: any,
	headers?: any;
}

const BlackFridayBannerArray = [
	{
		title: "Dein Zuhause. Einzigartig für Dich.",
		subTitle: "Möbel auf Raten zu 0 % Zins. Sofort besitzen, später zahlen. Ab CHF 43 / Monat.",
		buttonLabel: "Auswahl entdecken",
		image: "/assets/videos/black-friday-desktop-video-2.mp4",
		mobileImage: "/assets/videos/black-friday-desktop-video-2.mp4",
		buttonLink: t => filterUrl([], urlParams.category, "sofas", t)
	},
	{
		title: "Der einfachste Weg, Dein Zuhause einzurichten",
		subTitle: "Persönliche Möbel-Empfehlungen von unseren Experten",
		buttonLabel: "Jetzt ausprobieren",
		image: "/assets/images/blackFriday/mhp-black-friday-image-2.png",
		mobileImage: "/assets/images/blackFriday/mhp-black-friday-mobile-image-3.png",
		buttonLink: () => "https://skh5fu1vwwv.typeform.com/to/uspdZHAq"
	},
	{
		title: "Dein Sofa. Einzigartig für Dich.",
		subTitle: "Hol Dir jetzt Deine neue Wohlfühloase. Zahlen kannst Du auch später noch.",
		buttonLabel: "Sofas entdecken",
		image: "/assets/images/blackFriday/mhp-black-friday-image-3.png",
		mobileImage: "/assets/images/blackFriday/mhp-black-friday-mobile-image-4.png",
		buttonLink: t => filterUrl([], urlParams.category, "sofas", t)
	}
]

const BannerHomeSpringCampaign: React.FC<Props> = () => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const isBrandsForEmployees = process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE === "mhp_bfe"
	const onBtnClick = () => {
		setLoading(true)
	}

	const {reactGA} = useContext(AnalyticsContext)
	return (
		<div className="home-banner-slider">
			<Slides dots={true} arrowColor="white">
				{BlackFridayBannerArray?.map(({image, mobileImage, buttonLabel, title, subTitle, buttonLink}, i) =>
					<section key={i} className="home-banner mb-md-0 position-relative">
						{i === 0 ?
							<>
								<div className="video-wrapper">
									<video autoPlay muted loop>
										<source src={image} type="video/mp4"/>
										Your browser does not support the video tag.
									</video>
								</div>
							</>	:
							<>
								<Image src={image} layout={"fill"} objectFit={"cover"} alt="furniture image" className="d-none d-md-block"/>
								<Image src={mobileImage} layout={"fill"} objectFit={"cover"} alt="furniture image" className="d-md-none"/>
							</>
						}
						<div className={"gradient-black-transparent"}/>
						<div className={`container text-white ${i === 0 ? "video-content" : "position-relative"}`}>
							<div className={"row pb-5 home-banner-height align-items-end align-items-lg-end justify-content-center"}>
								<div className={"col-12"}>
									<div className={`text-white text-center ${isBrandsForEmployees ? "mb-4" : "mb-5"}`}>
										<Typography variant="heading1">{title}</Typography>
										<Typography variant="bodyLgBold" semanticTag="p" className={"text-white mt-2 text-center"}>{subTitle}</Typography>
									</div>
									<div className={"row justify-content-center mt-3"}>
										<div className={"col-12 col-md-6 col-lg-auto"}>
											<div className={"d-grid banner-btn"}>
												<Button label={buttonLabel}
													textColor="#212529"
													color="#fff"
													bgColor="#fff"
													hoverColor="#212529"
													outline={false}
													paddingY="3"
													paddingX={"5"}
													borderRadius={20}
													icon={<img src="/assets/icons/arrow-right.svg"/>}
													iconOnHover={<img src="/assets/icons/arrow-right-white.svg"/>}
													href={buttonLink(t)}
													className={"shadow banner-btn"}
													isLoading={loading} onClick={() => {
														onBtnClick()
														reactGA?.event({
															category: gaCategories.homePage,
															action: gaEvents.letsGoButton,
															label: gaEvents.letsGoButton,
															nonInteraction: false
														})
													}}/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				)}
			</Slides>
		</div>
	)
}

BannerHomeSpringCampaign.propTypes = {
	reviews: PropTypes.any
}

export default BannerHomeSpringCampaign
