import React, {useContext, useMemo} from "react"
import BikesCollectionItem from "./BikesCollectionItem/BikesCollectionItem"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import {filterUrl} from "../filter/FilterUrl"
import {BrandPages, CatCollectionPages} from "../../constants"
import {findBikeAttributeByName} from "../../utility/Helper"
import Button from "../reusable/components/Button"
import DataContext from "../../context/DataContext"
import {getBikesbyCat} from "../../utility/helpers"
import {Slides} from "../reusable/components/Slides"
import ComparisonPLPFixedComponent from "./ComparisonPLPFixedComponent"
import AnalyticsContext from "../../context/AnalyticsContext"
import {colors} from "../../colors/Colors"

type Props = {
  catName: string,
  noButton?: boolean,
  occasion?: boolean,
  featured?: boolean
}
const Statuses = {
	instock: 1,
	onbackorder: 1,
	outofstock: 2
}
const CatBikesListing: React.FC<Props> = ({featured, catName, noButton, occasion}) => {
	const {bikes} = useContext(DataContext)
	const _bikes = useMemo(() => getBikesbyCat(bikes, catName), [bikes, catName])
	const bikesList = BrandPages[catName] ? _bikes.filter(bike => bike.stock_status !== "outofstock") :
		CatCollectionPages[catName] ? _bikes.sort((a, b) => Statuses[a.stock_status] - Statuses[b.stock_status]) :
			_bikes
	const {t} = useTranslation()

	const {reactGA} = useContext(AnalyticsContext)

	const sortedBikes = featured ? bikesList.sort((a, b) => {
		const bikeSizeA = findBikeAttributeByName(a, "FeaturedPosition")
		const bikeSizeB = findBikeAttributeByName(b, "FeaturedPosition")

		return bikeSizeA - bikeSizeB
	}) : bikesList

	return (
		<section>
			{sortedBikes.length > 0 ? (
				<div
					className={"row pb-3 pb-lg-5 mx-1 mx-sm-0 justify-content-center align-items-center bikes-collection rounded"}>
					<div className={"col-12 d-block mt-md-5 mb-4 mb-md-3 p-0"}>
						<Typography semanticTag="h2" className={"text-md-center"}
							variant="heading1Black">
							{t("Topmodelle")}
						</Typography>
					</div>
					<div className="px-0 bikes-slider-wrapper d-block d-md-none">
						<div className="bikes-slider-wrapper d-block d-md-none mb-0 pb-3" style={{minHeight: 545, maxHeight: 755}}>
							<Slides centerMode centerModePadding={{left: 0, bottom: 0, right: 25}} >
								{bikesList.map((bike, idx) => {
									return (
										<div style={{paddingLeft: "12px"}} onClick={() => {
										}} key={idx} className={"col col-md-6 my-md-0"}>
											<BikesCollectionItem isCarouselElement cardWidth="93%" bike={bike} withOneImage />
										</div>)
								})}
							</Slides>
						</div>
						<div className="mb-5">
							<ComparisonPLPFixedComponent sticky={false}/>
						</div>
					</div>
					<div className="d-none d-md-block ">
						<div className="row gx-4 justify-content-center">
							{
								sortedBikes.length > 0 ? bikesList.map((bike, i) => {
									return (
										<div className="col-md-3" key={bike.id}>
											<BikesCollectionItem bike={bike} withArrows cardWidth="308px"/>
										</div>
									)
								}) : null
							}
							<div className="mt-lg-4">
								<ComparisonPLPFixedComponent sticky={false}/>
							</div>
						</div>
					</div>
					<div className="d-md-block d-none">
						{featured ?
							<div className={"w-100 w-md-auto col-12 mt-3 text-center"}>
								<Button href={filterUrl({}, null, null, t)} className={"col-12 col-md-3"} label={t("Alle e-bikes")} spinnerColor="#212529"
									color="#fff" textColor="#212529" hoverColor="#212529" outline/>
							</div> : null}
					</div>
					<div className="d-md-none px-0">
						{featured ?
							<div className={"w-100 w-md-auto col-12 mt-2 mb-2 text-center"}>
								<Button href={filterUrl({}, null, null, t)} className={"col-12"} label={t("Alle e-bikes")} spinnerColor="#212529"
									paddingY="3"	bgColor={colors.primary} textColor={colors.white} high/>
							</div> : null}
					</div>
				</div>
			) :
				null
			}
		</section>
	)
}

CatBikesListing.propTypes = {
	catName: PropTypes.string.isRequired,
	noButton: PropTypes.bool,
	occasion: PropTypes.bool
}

export default CatBikesListing
