import React from "react"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import {Slides} from "../../reusable/components/Slides"
import Link from "next/link"
import styles from "./InstagramSection.module.scss"

const InstagramSection = () => {
	const {t} = useTranslation(["common"])
	return (
		<section className=" col-12 mt-4 py-3 pb-md-0">
			<div className="container text-md-center mb-4 mb-md-5">
				<Typography variant={"heading2Black"} className="mb-3 d-none d-md-block">{t("follow-MHPl-on-instagram")}</Typography>
				<Typography variant={"heading2Black"} className="mb-3 d-md-none" style={{fontSize: 28}}>{t("follow-MBPl-on-instagram")}</Typography>
				<Typography variant={"bodyLg"} semanticTag="span">{t("see-our-photos-and-reels")}</Typography>
				<Link href={"https://www.instagram.com/myhomeplan.ch/"}>
					<a target="_blank" style={{backgroundColor: "#FFE9C2", borderRadius: "4px"}} className="py-1 px-1 px-md-2  ms-2 link-reset  bg-primary-pale rounded">
						<Typography className="pointer" variant={"bodyLgBold"} semanticTag="span">@myhomeplanch</Typography>
					</a>
				</Link>
			</div>

			<div className={`${styles.galleryElement} d-md-none ps-3`}>
				<Slides autoplay={false} centerMode centerModePadding={{left: 0, bottom: 0, right: 25}}>
					{MHPphotos.map(({source}, index) => {
						return (
							<Image key={index} src={source} layout={"responsive"} width={259} height={259} alt="pic"/>
						)
					})}
				</Slides>
			</div>

			<div className="container d-none d-md-flex justify-content-start">
				{MHPphotos.map(({source}, index) => {
					return (
						<div key={index} style={{height: "auto", minWidth: "20%"}}>
							<Image className={`${index === 0 && styles.galleryFirstElement} ${index === MHPphotos.length - 1 && styles.galleryLastElement}`} src={source} layout={"responsive"} width={259} height={259} alt="pic"/>
						</div>
					)
				})}
			</div>

		</section>
	)
}

export default InstagramSection

const photos = [
	{source: "/assets/images/InstagramCover1.jpg"},
	{source: "/assets/images/InstagramCover2.jpg"},
	{source: "/assets/images/InstagramCover3.jpg"},
	{source: "/assets/images/InstagramCover4.jpg"},
	{source: "/assets/images/InstagramCover5.jpg"}
]

const MHPphotos = [
	{source: "/assets/images/InstaMyHomePlan1.jpg"},
	{source: "/assets/images/InstaMyHomePlan2.jpg"},
	{source: "/assets/images/InstaMyHomePlan3.jpg"},
	{source: "/assets/images/InstaMyHomePlan4.jpg"},
	{source: "/assets/images/InstaMyHomePlan5.jpg"}
]
