import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import DataContext from "../../../context/DataContext"
import Typography from "../../reusable/Typography/Typography"
import LayoutContext from "../../../context/LayoutContext"
import styles from "./LogoGallery.module.scss"
import Image from "next/image"
import Link from "next/link"

enum bgColor {
  white = "white",
  black = "black",
  grey = "grey",
  orange = "orange"
}

interface Props {
  hideAllBrandsButton?: boolean
  bgColor?: keyof typeof bgColor
  targetHeight?: number
  col?: string
  noPadding?: boolean
  isSlider?: boolean
}

const logosArray = [
	{
		image: "/assets/icons/Kave.svg",
		imageMobile: "/assets/icons/Kave-mob.svg"
	},
	{
		image: "/assets/icons/Bolia.svg",
		imageMobile: "/assets/icons/Bolia-mob.svg"
	},
	{
		image: "/assets/icons/Innovation.svg",
		imageMobile: "/assets/icons/innovation-mob.svg"
	},
	{
		image: "/assets/icons/Furninova.svg",
		imageMobile: "/assets/icons/Furninova-mob.svg"
	},
	{
		image: "/assets/icons/Conform.svg",
		imageMobile: "/assets/icons/Conform-mob.svg"
	}
]

const LogoGallery: React.FC<Props> = ({hideAllBrandsButton, bgColor, col, targetHeight, noPadding, isSlider}) => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const {brands} = useContext(DataContext)
	const {isMobile} = useContext(LayoutContext)
	const brandsForMobile = []
	const newArrForSplice = [...brands]

	while (newArrForSplice.length) {
		if (newArrForSplice.length > 6) {
			const arrToPush = newArrForSplice.splice(0, 6)
			brandsForMobile.push(arrToPush)
		} else if (newArrForSplice.length <= 6) {
			const arrToPush = newArrForSplice.splice(0)
			brandsForMobile.push(arrToPush)
		}
	}

	return (
		<section
			className={"container mt-md-5 py-md-5"}>
			<div className={`${styles.wrapperRarius}`} style={{backgroundColor: "#F8F8F8"}}>
				{hideAllBrandsButton ? null :
					<Typography variant={`${isMobile ? "bodyLgBlack" : "heading2Black"}`} semanticTag="h3"
						className={"mb-3 text-md-center"}
						style={{fontSize: isMobile ? 28 : "", lineHeight: isMobile ? "36px" : ""}}>
						{t("Wir sind offizielle Partner von:")}
					</Typography>
				}
				<div className="row px-2 list-unstyled gap-3 gap-md-0 justify-content-md-center align-items-center">
					{logosArray.map((partner, i) =>
						<React.Fragment key={i}>
							<div className="d-none d-md-block col-auto">
								<Link href={"/"} key={i}>
									<a>
										<Image src={partner.image}
											alt="partner grey logo" width={"206"} height={"48"} objectFit={"contain"}/>
									</a>
								</Link>
							</div>
							<div className="d-md-none col-5">
								<Link href={"/"} key={i}>
									<a>
										<Image src={partner.imageMobile} alt="partner grey logo" width={"135"} height={"48"} objectFit={"contain"}/>
									</a>
								</Link>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</section>
	)
}

export default LogoGallery
