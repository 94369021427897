import React, {useContext, useState} from "react"
import LayoutContext from "../../context/LayoutContext"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"

type Props = {
	content: any[]
}

const contentArr = [
	{
		title: "Wofür MyHomePlan steht",
		description: [
			"MyHomePlan ist Teil der MyBikePlan-Familie und verfolgt ein Ziel: Die Dinge, die Du liebst, erschwinglich zu machen! Wir möchten, dass Du kaufen kannst, WAS Du willst, WANN Du willst. Wir bieten Dir flexible Zahlungsmöglichkeiten, eine grosse Auswahl und eine Gratis-Beratung, die genau auf Dich zugeschnitten ist.",
			"Stell Dir vor, Du ziehst um: Statt mehrere tausend Franken auf einmal auszugeben, fügst Du Deiner monatlichen Miete einfach einen Betrag hinzu und zahlst Deine Möbel ganz einfach in Raten mit 0 % Zins ab. Wähle zwischen einer Direktzahlung oder einer Zahlung in Raten (6, 12, 24 oder 36 Monate) zu 0 % Zins und lass Dir Deine neuen Möbel bequem vor die Haustür liefern. So reisst die nächste 'Züglete' garantiert kein Loch ins Portemonnaie. Und das Beste daran? Das Möbelstück gehört dabei von Tag 1 an Dir."
		]
	},
	{
		title: "0 % Zins – wie geht das überhaupt?",
		description: "Normalerweise zahlt man bei Monatsraten einen gewissen Zinssatz auf den Originalpreis dazu. Nicht so bei uns. Unsere Monatsraten gibt es dank unserer Zusammenarbeit mit unseren Finanzpartnern zu 0 % Zins. Heisst für Dich: Du zahlst keinen Rappen mehr, als wenn Du alles auf einmal zahlen würdest."
	},
	{
		title: "Wie MyHomePlan funktioniert",
		description: "Wähle einfach Deine Wunschmöbel und bestelle sie über unsere Website. Im Anschluss führen wir eine Bonitätsprüfung durch. Ist diese erfolgreich, erhältst Du Deinen Vertrag. Selbstverständlich ist unser Team die ganze Zeit über für Deine Fragen und Anliegen da."
	},
	{
		title: "Kostenlose Heimlieferung",
		description: "Nach dem Unterzeichnen des Vertrags plant unser Speditionspartner mit Dir die Lieferung. Deine Möbel werden Dir kostenlos vor die Haustür gebracht. Bequem und stressfrei."
	}
]

const HomeTextComponent: React.FC<Props> = ({content}) => {
	const {isMobile} = useContext(LayoutContext)
	const {t} = useTranslation("common")

	const [isToggleOpen, setIsToggleOpen] = useState(false)

	const styleOverlay = isToggleOpen ? {backgroundColor: "transparent"} : {
		background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 95%)"
	}

	const style = isToggleOpen ? {height: "100%", overflow: "hidden"} : {height: isMobile ? "56vh" : "35vh", overflow: "hidden"}
	return (
		<section className="position-relative mx-auto" style={{maxWidth: 800, marginBottom: isMobile ? 48 : 100}}>
			<div className={`container ${isMobile ? "mt-2 pt-3" : ""} `} style={{marginTop: 100, ...style}}>
				<div className="position-relative row">
					<div className={`col-12 ${!isMobile && "text-center"}  text-md-start`}>
						{contentArr.map((text, idx) => {
							const {title, description} = text
							return (
								<div key={idx} className="mb-md-4">
									<Typography variant="bodyLgBold" semanticTag="h4">{title}</Typography>
									{Array.isArray(description) ? (
										description.map((paragraph, pIdx) => (
											<Typography key={pIdx} variant="bodyLg" semanticTag="p">{paragraph}</Typography>
										))
									) : (
										<Typography variant="bodyLg" semanticTag="p">{description}</Typography>
									)}
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<div
				className={`${
					isToggleOpen ? "d-none" : ""
				} position-absolute start-0 end-0 bottom-0 vh-20`}
				style={styleOverlay}
			/>
			<div
				className={"pt-3 pt-md-1 container position-relevant"}>
				<div
					className={"col-12 text-center"}
					style={{
						cursor: "pointer"
					}}
					onClick={() => setIsToggleOpen(!isToggleOpen)}>
					{isToggleOpen ? (
						<div className="d-flex mt-3 align-items-center">
							<Typography variant="bodyLgBold" semanticTag="h3" className="mb-0">{t("Show less")}</Typography>
							<div className="d-flex justify-content-center align-items-center" style={{width: 20, height: 20, marginLeft: 10}}>
								<Image src={"/assets/icons/chevron-up-icon.svg"} width={8.33} height={5}/>
							</div>
						</div>
					) : (
						<div
							className="d-flex mt-3 align-items-center bottom-0 position-absolute mt-3">
							<Typography variant="bodyLgBold" semanticTag="h3" className="mb-0">{t("Show more")}</Typography>
							<div className="d-flex justify-content-center align-items-center" style={{width: 20, height: 20, marginLeft: 10}}>
								<Image src={"/assets/icons/chevron-down-icon.svg"} width={8.33} height={5}/>
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}

export default HomeTextComponent
