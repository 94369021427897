/* eslint-disable no-negated-condition */
import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import CatBikesListing from "../../bikesCollection/CatBikesListing"
import BlockText from "../../../contentful/render/BlockText"
import DataContext from "../../../context/DataContext"
import {getBikesbyCatSlugs} from "../../../utility/helpers"
import {Slides} from "../../reusable/components/Slides"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import BikesCollectionItem from "../../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import ComparisonPLPFixedComponent from "../../bikesCollection/ComparisonPLPFixedComponent"
import Button from "../../reusable/components/Button"
import Typography from "../../reusable/Typography/Typography"
import {isCategory} from "../../../utility/Helper"
import {colors} from "../../../colors/Colors"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {filterUrl} from "../../filter/FilterUrl"
import {useRouter} from "next/router"
import Image from "next/image"
import urlParams from "../../../utility/UrlParams"

type Props = {
	content?: any[];
	hideBikes?: boolean;
	hideTopModels?: boolean;
};

const MBPSlugsList = [
	{
		title: "E-Mountain",
		slugName: "e-mountain",
		link: "mountain-e-bikes",
		buttonTitle: "Alle Mountain E-Bikes"
	},
	{
		title: "E-City",
		slugName: "e-urban",
		link: "city-e-bikes",
		buttonTitle: "Alle City E-Bikes"
	},
	{
		title: "E-Trekking",
		slugName: "e-trekking",
		link: "trekking-e-bikes",
		buttonTitle: "Alle Trekking E-Bikes"
	},
	{
		title: "45 Km/h",
		slugName: "45-km",
		link: "e-bikes-45-km-h",
		buttonTitle: "All 45km/h E-Bikes"
	}
]

const MHPlSlugsList = [
	{
		title: "Sofas",
		slugName: "sofa",
		icon: "/assets/icons/sofa.svg",
		link: "sofas",
		buttonTitle: "All Sofas"
	},
	{
		title: "Table",
		slugName: "table",
		icon: "/assets/icons/tables.svg",
		link: "table",
		buttonTitle: "All Tables"
	},
	{
		title: "Bed",
		slugName: "bed",
		icon: "/assets/icons/bed.svg",
		link: "bed",
		buttonTitle: "All Beds"
	},
	{
		title: "Aufbewahrung",
		slugName: "aufbewahrung",
		icon: "/assets/icons/furniture.svg",
		link: "aufbewahrung",
		buttonTitle: "Alle Aufbewahrung"
	}
]

const slugLists = {
	mybikeplan: MBPSlugsList,
	myhomeplan: MHPlSlugsList
}

const storeFront = process.env.NEXT_PUBLIC_STOREFRONT

const HomeCatBoxes: React.FC<Props> = ({content, hideBikes = false, hideTopModels = false}) => {
	const router = useRouter()
	const slugList = slugLists[storeFront]

	const topModelle = {
		title: "Topmodelle",
		slugName: router.locale === "de" ? "featured" : "featured-fr",
		link: "featured",
		buttonTitle: "Alle Produkte"
	}

	const {bikes} = useContext(DataContext)
	const {reactGA} = useContext(AnalyticsContext)
	const [activeSlug, setActiveSlug] = useState(topModelle.slugName)
	const [activeLink, setActiveLink] = useState(topModelle.link)
	const [activeBtnTitle, setActiveBtnTitle] = useState(topModelle.buttonTitle)
	const bikesList = getBikesbyCatSlugs(bikes, [activeSlug])
	const promotedBikesList = bikesList.filter(bike => isCategory(bike, "homepage"))
	const homeCatBikesList = promotedBikesList.length ? promotedBikesList : bikesList
	const {t} = useTranslation("common")

	const activeUrl = activeBtnTitle === "Alle Produkte" ?
		filterUrl({}, null, null, t) :
		filterUrl({[urlParams.category]: activeLink}, null, null, t)

	const getSlugHandler = (slug: string, link: string, btnTitle: string) => {
		setActiveSlug(slug)
		setActiveLink(link)
		setActiveBtnTitle(btnTitle)
	}

	const {primary, white} = colors

	return (
		<section id={"cat-boxes"}>
			<div className={"pb-0 pb-md-3"}>
				{process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ?
					<div className="d-flex flex-column justify-content-center align-items-center container text-center mt-md-5">
						{hideBikes ? null : (<>
							{process.env.NEXT_PUBLIC_ORGANIZATION ? <div className=" row justify-content-between align-items-start mt-5 mt-md-0  text-start gap-4 ">
								<div className="col-12 col-md-6">
									<div className="d-flex justify-content-start align-items-center gap-3"><Image src="/assets/icons/sbb_logo_red_bg.svg" width={40} height={26} />
										<Typography variant={"heading2Black"} semanticTag={"h2"} className="d-none d-md-block">{t("sbb-title")}</Typography></div>
									<Typography variant={"heading2Black"} semanticTag={"h2"} style={{fontSize: 32}} className="d-md-none">{t("sbb-title")}</Typography>
									<Typography variant={"bodyLg"} semanticTag={"p"} className="mt-3">{t("sbb-paragraph")}
									</Typography></div>
								<div className="col-12 col-md-6">
									<Typography variant={"heading2Black"} semanticTag={"h2"} className="d-none d-md-block">{t("homecat-title")}</Typography>
									<Typography variant={"heading2Black"} semanticTag={"h2"} style={{fontSize: 32}} className="d-md-none">{t("homecat-title")}</Typography>
									<Typography variant={"bodyLg"} semanticTag={"p"} className="mt-3">{t("homecat-paragraph")}
									</Typography></div>
							</div> : <div className="col-12 mt-5 mt-md-0 col-lg-8 text-start text-md-center ">
								<Typography variant={"heading2Black"} semanticTag={"h2"} className="d-none d-md-block">{t("homecat-title")}</Typography>
								<Typography variant={"heading2Black"} semanticTag={"h2"} style={{fontSize: 32}} className="d-md-none">{t("homecat-title")}</Typography>
								<Typography variant={"bodyLg"} semanticTag={"p"} className="mt-3">{t("homecat-paragraph")}
								</Typography>
							</div> }</>
						)}
					</div> :
					<div className="d-flex flex-column justify-content-center align-items-center">
						{hideBikes ? null : (
							<div className="col-12 mt-5 mt-md-0 col-lg-8 text-md-center">
								{content.map(({fields}, idx) => {
									return (
										<BlockText
											key={idx}
											fields={fields}
											textClassname={"mt-0 mb-2 mb-md-4"}
										/>
									)
								})}
							</div>
						)}
					</div>
				}

				<div className="ps-3 ps-md-0 container-md">
					<div className="px-0 d-md-flex justify-content-center bike-types mb-md-2" style={{width: "100%", overflowX: "scroll"}}>
						<ul className="px-0 mb-0 d-flex align-items-center list-unstyled bikes-tabs scrollbar-hide"
							style={{height: 48, overflowX: "scroll"}}>
							{[topModelle, ...slugList].map((bike, index) => (
								<li key={index} className="px-3 bike-tab d-flex justify-content-center align-items-center cursor-pointer"
									style={{
										cursor: "pointer",
										color: activeSlug === bike.slugName ? "#212529" : "#595D61",
										fontWeight: activeSlug === bike.slugName ? 700 : "500",
										borderBottom: activeSlug === bike.slugName ? "2px solid #6E9060" : ""
									}}
									onClick={() => getSlugHandler(bike.slugName, bike.link, bike.buttonTitle)}>
									{bike.ico && <span className="d-none d-md-flex align-items-center">
										<Image src={bike.icon} width={24} height={24} alt={"furniture icon"} objectFit="contain"/>
									</span>}
									<Typography variant={activeSlug === bike.slugName ? "bodyLgBold" : "bodyLg"} className={"text-nowrap ms-md-2"}>{bike.title === "Topmodelle" ? t("Topmodelle") : t(bike.title)}</Typography>
								</li>
							))}
						</ul>
					</div>
					<div className="bikes-slider-wrapper d-block d-md-none mb-0 pb-3" style={{minHeight: 545, maxHeight: 755}}>
						<Slides centerMode centerModePadding={{left: 0, bottom: 0, right: 25}} >
							{homeCatBikesList.map((bike, idx) => {
								return (
									<div style={{paddingLeft: "12px"}} onClick={() => {
										reactGA?.event({
											category: gaCategories.homePage,
											action: gaEvents.topModels,
											label: bike.name,
											nonInteraction: false
										})
									}} key={idx} className={"col col-md-6 my-md-0"}>
										<BikesCollectionItem cardWidth="90%" bike={bike} withOneImage />
									</div>)
							})}
						</Slides>
						<div style={{marginTop: storeFront === "mybikeplan" ? "" : "2rem"}} className={"container ps-0 col-12 pt-5 pb-1 "}>
							<Button
								label={`${t(activeBtnTitle)}`}
								href={activeUrl}
								color="#212529"
								textColor={white} className="w-100"
								bgColor={primary} high paddingY={"3"}/>
						</div>
					</div>

					<div className="bikes-slider-wrapper d-none d-md-block d-md-flex justify-content-center flex-wrap row gx-5 col-12" style={{minHeight: 565, maxHeight: 755}}>
						{homeCatBikesList.length > 4 ?
							<Slides autoplay={false} centerMode slidesToShow={4} arrows draggable={false}>
								{homeCatBikesList.map((bike, idx) => {
									return (
										<div onClick={() => {
											reactGA?.event({
												category: gaCategories.homePage,
												action: gaEvents.topModels,
												label: bike.name,
												nonInteraction: false
											})
										}} key={idx} className={"mt-3 mb-5 h-100 col-3"}>
											<BikesCollectionItem isCarouselElement cardWidth="95%" bike={bike} withOneImage/>
										</div>)
								})}
							</Slides> :
							homeCatBikesList.map((bike, idx) => {
								return (
									<div onClick={() => {
										reactGA?.event({
											category: gaCategories.homePage,
											action: gaEvents.topModels,
											label: bike.name,
											nonInteraction: false
										})
									}} key={idx} className={"mt-3 mb-5 h-100 col-3"} style={{minHeight: 500}}>
										<BikesCollectionItem bike={bike} withOneImage/>
									</div>)
							})
						}
						<div className="mt-4">
							<ComparisonPLPFixedComponent sticky={false}/>
						</div>
					</div>
					<div className="container d-none d-md-flex mt-2 justify-content-center col-12">
						<Button
							className="position-relative"
							label={`${t(activeBtnTitle)}`}
							href={activeUrl}
							color={white}
							textColor={white}
							bgColor={primary} high paddingY={"3"}/>
					</div>
				</div>
				{hideTopModels ? null : <div className="ps-3 container-md ps-md-0 mt-5">
					<CatBikesListing catName={"Featured"} noButton featured/>
				</div>}
			</div>
		</section>
	)
}

export default HomeCatBoxes
