import React, {useContext} from "react"
import {ModalContext} from "../../../context/ModalContext"
import {useTimeOutScrollModal} from "../../../hooks/useTimeOutScrollModal"
import DiscountForNewsletterModalContent from "../ModalsContent/DiscountForNewsletterModalContent"

interface Props {
	ModalComponent?: React.ReactNode
}
const ModalTrigger:React.FC<Props> = ({ModalComponent}) => {
	const {closeModal} = useContext(ModalContext)
	useTimeOutScrollModal({scroll: false, modalContent: ModalComponent ? ModalComponent : <DiscountForNewsletterModalContent closeModal={closeModal}/>})
	return (
		null
	)
}

export default ModalTrigger
