import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../context/ModalContext"

export const useTimeOutScrollModal = ({scroll = false, modalContent = null}:{scroll?: boolean, modalContent?: React.ReactNode} = {}): void => {
	const {setModalContent, startTimeout, cancelTimeout, closeModal} = useContext(ModalContext)
	const [scrollHeight, setScrollHeight] = useState<number | null>(null)

	const handleScroll = () => {
		if ((document.documentElement.clientWidth + window.pageYOffset) >= scrollHeight) {
			window.removeEventListener("scroll", handleScroll)
			cancelTimeout()
			setModalContent(modalContent)
			startTimeout(0)
		}
	}

	useEffect(() => {
		setScrollHeight(Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight
		))
	}, [])

	useEffect(() => {
		if (scrollHeight && process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE !== "mhp_bfe") {
			setModalContent(modalContent)
			startTimeout(30000)

			if (scroll) {
				window.addEventListener("scroll", handleScroll)
			}

			return () => {
				if (scroll) {
					window.removeEventListener("scroll", handleScroll)
				}

				cancelTimeout()
			}
		}
	}, [scrollHeight])
}
