import React, {useContext} from "react"
import Image from "next/image"
import LayoutContext, {devices} from "../../../context/LayoutContext"
import HorizontalDashedLine from "./HorizontalDashedLined"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
	className?: string,
	blockTilesHowItWorks: any
}

const tilesArr = [
	{
		title: "Finden",
		description: "Stöbere in unserem Sortiment und finde die passenden Möbel für Dein neues Zuhause",
		icon: "/assets/icons/sofa-blue.svg"
	},
	{
		title: "Personalisieren",
		description: "Wähle zwischen einer Ratendauer von 6-36 Monaten. Auch eine Anzahlung ist möglich.",
		icon: "/assets/icons/sign-contract.svg"
	},
	{
		title: "Wohnen",
		description: "Wir liefern Deine neuen Möbelstücke gratis zu Dir nach Hause. Sie gehören von Anfang an Dir.",
		icon: "/assets/icons/rocket.svg"
	}
]

const HowItWorks = ({blockTilesHowItWorks, className = ""}: Props): React.ReactElement => {
	const {isMobile, device} = useContext(LayoutContext)
	const marginTop = (isMobile || device === devices.tablet) ? "5px" : null
	const {title, tiles} = blockTilesHowItWorks.fields
	const {t} = useTranslation(["common", "konzept"])

	return (
		<section className={className}>
			<div className="container">
				<div className="d-block d-md-none">
					<Typography variant={`${isMobile ? "heading2" : "heading2Black"}`} semanticTag="h1"
						className={`${!isMobile && "text-center mb-3"}`}
						style={{fontSize: isMobile ? 28 : "", marginBottom: isMobile ? 35 : ""}}>Wie funktioniert MyHomePlan?</Typography>
					{(isMobile || device === devices.tablet) ? null : <HorizontalDashedLine tiles={tiles}/>}
					<div className="row text-lg-center">

						{tilesArr.map((item, index) => {
							const {icon, description, title} = item

							return (
								<div key={title} className="col-12 col-lg-4">
									<div className="row justify-content-center position-relative">
										{(isMobile || device === devices.tablet) ? <>
											<div className="col-2 d-flex flex-column align-items-center position-relative text-center">
												<Image className="detail-img" src={icon} alt="orange circle with hand pointing" width={40} height={40}
												/>
												<div className="dots-wrapper d-flex align-items-end position-absolute" style={{bottom: 24}}>
													{index + 1 !== tiles.length && <div className="solid-lines"/>}
												</div>
											</div>
										</> :
											null}
										<div className="col-10 col-lg-12" style={{
											marginTop
										}}>
											<Typography variant="bodyLgBlack" semanticTag="h3" style={{
												fontSize: 20}} className="mb-2">
												{title}
											</Typography>
											<Typography variant="bodyLg" semanticTag="p">{description}</Typography>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="d-none d-md-block">
					<Typography variant={"heading2Black"} semanticTag="h1" className={"text-center mb-5"}>Wie funktioniert MyHomePlan?</Typography>
					<div className="row gx-4 d-flex justify-content-between">
						{tilesArr.map((item, index) => {
							const {icon, description, title} = item

							return (
								<div key={index} className="position-relative col-4 pe-0 d-flex justify-content-center">
									<div>
										<Image src={icon} height={40} width={40}/>
									</div>
									<div className="mt-2 ms-3">
										<div className="d-flex align-items-center">
											<Typography variant="bodyLgBlack" semanticTag="h3" style={{
												fontSize: 20
											}} className="mb-1 pe-4 col-auto">
												{title}
											</Typography>
											{ index !== (tiles.length - 1) &&
											<div className="position-absolute col-auto" style={{height: 1, right: -26, border: "1px solid #212529", width: "40%"}}/>
											}
										</div>
										<div style={{maxWidth: 285}}>
											<div className="how-it-works-paragraph">
												<Typography variant="bodyLg" semanticTag="p">{description}</Typography>
											</div>
										</div>
									</div>
								</div>)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}

export default HowItWorks
