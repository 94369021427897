import React from "react"
import Image from "next/image"
import getImageUrl from "../../../contentful/image/image"

type Props = {
	referral?: boolean
	tiles?: any[]
}

const HorizontalDashedLine: React.FC<Props> = ({referral, tiles}) => {
	return (
		<>
			{referral ? (
				<div className={"position-relative d-none d-lg-block mb-2"}>
					<div className={"position-absolute top-0 start-0 bottom-0 end-0"}>
						<div className={"row justify-content-center align-items-center"} style={{
							height: 96
						}}>
							<div className={"col-12 col-lg-9 ps-5 pe-5"}>
								<div className={"border-primary border-3"} style={{
									borderBottomStyle: "dashed",
									marginTop: 1
								}}/>
							</div>
						</div>
					</div>
					<div className={"row justify-content-center position-relative"}>
						<div className={"col-12"}>
							<div className={"row"}>
								<div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/bike.svg"
												alt="orange circle with bike"
												width={96} height={96}
											/>
										</div>
									</div>
								</div>
								<div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/deal.svg"
												alt="orange circle with shaking hands"
												width={96} height={96}
											/>
										</div>
									</div>
								</div>
								<div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/cash.svg"
												alt="orange circle with cash"
												width={96} height={96}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={"position-relative d-none d-lg-block mb-2"}>
					<div className={"position-absolute top-0 start-0 bottom-0 end-0"}>
						<div className={"row justify-content-center align-items-center"} style={{
							height: 96
						}}>
							<div className={"col-12 col-lg-9 ps-5 pe-5"}>
								<div className={"border-primary border-3"} style={{
									borderBottomStyle: "dashed",
									marginTop: 1
								}}/>
							</div>
						</div>
					</div>
					<div className={"row justify-content-center position-relative"}>
						<div className={"col-12"}>
							<div className={"row"}>
								{tiles?.map(({fields}, idx) => {
									const {icon} = fields
									return (
										<div key={idx} className={"col"}>
											<div className={"row justify-content-center"}>
												<div className={"col-auto bg-white"}>
													<Image className="detail-img "
														src={getImageUrl(icon)}
														alt="orange circle with hand pointing"
														width={86} height={86}
													/>
												</div>
											</div>
										</div>
									)
								})}
								{/* <div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/howItWorks/hand-pointing.svg"
												alt="orange circle with hand pointing"
												width={96} height={96}
											/>
										</div>
									</div>
								</div>
								<div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/howItWorks/check.svg"
												alt="orange circle with check symbol"
												width={96} height={96}
											/>
										</div>
									</div>
								</div>
								<div className={"col"}>
									<div className={"row justify-content-center"}>
										<div className={"col-auto"}>
											<Image className="detail-img "
												src="/assets/icons/howItWorks/bike-circle-yellow.svg"
												alt="orange circle with bycicle"
												width={96} height={96}
											/>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default HorizontalDashedLine
